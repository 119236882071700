<template>
  <div id="customSolutionsPage">
    <HeroContainer
      v-if="heroData"
      @loaded="toggleShowPageTrue"
      :hero-data="heroData"
    />
    <HighlightsTowerContainer :highlights-tower-data="highlightsTowerData" />
    <ParagraphBackgroundImage
      v-if="paragraphBackgroundImageData"
      :container-data="paragraphBackgroundImageData"
    />
    <ApplicationListContainer
      class="application-list-container"
      :application-list-data="applicationListData"
    />
    <GreenAccordion :accordion-data="greenAccordionData" />
    <ProjectChecklistAccordion :accordion-data="preProjectChecklistData" />
    <HighlightsTowerContainer
      :highlights-tower-data="secondaryHighlightsTowerData"
    />
    <BlendingBenefitsHeader
      v-if="blendingBenefitsHeaderData"
      :blending-benefits-data="blendingBenefitsHeaderData"
    />
    <HighlightsTowerContainer
      :highlights-tower-data="thirdHighlightsTowerData"
    />
  </div>
</template>

<script>
import api from '@/api'
import ApplicationListContainer from '@/components/ApplicationList/ApplicationListContainer'
import HeroContainer from '@/components/Hero/HeroContainer'
import HighlightsTowerContainer from '@/components/HighlightsTower/HighlightsTowerContainer'
import viewMixin from '@/mixins/viewMixin'
import ParagraphBackgroundImage from '@/components/ui/ParagraphBackgroundImage'
import GreenAccordion from '@/components/Accordion/GreenAccordion'
import ProjectChecklistAccordion from '@/components/Accordion/ProjectChecklistAccordion'
import BlendingBenefitsHeader from '@/components/Header/BlendingBenefitsHeader'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'CustomSolutions',
  components: {
    BlendingBenefitsHeader,
    ProjectChecklistAccordion,
    GreenAccordion,
    ApplicationListContainer,
    HeroContainer,
    HighlightsTowerContainer,
    ParagraphBackgroundImage
  },
  mixins: [viewMixin],
  data() {
    return {
      applicationListData: {},
      heroData: [],
      highlightsTowerData: [],
      paragraphBackgroundImageData: null,
      greenAccordionData: [],
      preProjectChecklistData: [],
      secondaryHighlightsTowerData: [],
      blendingBenefitsHeaderData: null,
      thirdHighlightsTowerData: []
    }
  },
  mounted() {
    this.loadCustomSolutionsPage()
  },
  methods: {
    loadCustomSolutionsPage() {
      this.setCustomSolutionsData(
        apiHelper.readStoredResponse('customSolutions')
      )

      api.customSolutions.loadCustomSolutionsPage().then(response => {
        this.setCustomSolutionsData(response)
      })
    },
    setCustomSolutionsData(response) {
      if (!response || !response.entry) return

      this.heroData = response.entry.hero || []
      this.highlightsTowerData = response.entry.highlightsTower || []
      this.paragraphBackgroundImageData =
        response.entry.paragraphBackgroundImage[0] || null
      this.applicationListData = response.entry.applicationList
        ? response.entry.applicationList[0]
        : []
      this.greenAccordionData = response.entry.greenAccordion || []
      this.preProjectChecklistData = response.entry.preProjectChecklist || []
      this.secondaryHighlightsTowerData =
        response.entry.secondaryHighlightsTower || []
      this.blendingBenefitsHeaderData =
        response.entry.blendingBenefitsHeader[0] || null
      this.thirdHighlightsTowerData = response.entry.thirdHighlightsTower || []
    }
  }
}
</script>

<style lang="scss">
#customSolutionsPage {
  .application-list-container {
    box-shadow: 0 0 25px #525e6e;
  }
}
</style>
