<template>
  <div class="green-accordion-list">
    <Accordion
      class="accordion-item"
      v-for="accordionItem in accordionData"
      :key="accordionItem.id"
    >
      <template v-slot:header>
        <div class="container accordion-header">
          {{ accordionItem.titleText }}
        </div>
      </template>
      <template v-slot:content>
        <div class="container accordion-content">
          {{ accordionItem.textContent }}
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/ui/Accordion'
export default {
  name: 'GreenAccordion',
  components: {
    Accordion
  },
  props: {
    accordionData: {
      type: Array,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.green-accordion-list {
  @apply text-white;
  background-color: #525e6e;

  .accordion-item {
    &:not(:first-of-type) {
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.5);
    }

    .accordion-header {
      @apply py-8;
      font-family: FuturaPtDemi, serif;
      font-size: 26px;
      letter-spacing: 4px;
      transition: all 0.3s linear;

      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .accordion-content {
      @apply pb-8 px-8;
      max-width: 600px !important;
      font-size: 22px;
      letter-spacing: 2px;
      font-family: 'FuturaPtDemi', sans-serif;
    }
  }

  @media screen and (min-width: 600px) {
    .accordion-item {
      .accordion-header {
        @apply py-16;
        font-size: 32px;
      }

      .accordion-content {
        @apply pb-16 px-0;
        font-size: 28px;
      }
    }
  }
}
</style>
