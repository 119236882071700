<template>
  <div class="application-item flex flex-col justify-end items-center">
    <div class="image-container">
      <img :src="applicationImage.url" :alt="applicationImage.title" />
    </div>
    <div class="content-container">
      <p v-html="applicationItem.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationItem',
  props: {
    applicationItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    applicationImage() {
      return this.applicationItem.image[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.application-item {
  flex: 1 0 100%;
  max-width: 100%;

  .image-container {
    @apply flex items-center overflow-hidden mx-6;
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 20px;
    border: 15px solid #fff;
    border-radius: 50%;
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;

    &:hover {
      transform: scale(1.1);
    }

    img {
      @apply w-full;
    }
  }

  .content-container {
    p {
      font-size: 26px;
      font-family: FuturaPtMedium, sans-serif;
    }
  }

  @media screen and (min-width: 600px) {
    flex: 1 0 50%;
    max-width: 50%;

    .content-container {
      p {
        font-size: 28px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    flex: 1 0 33%;
    max-width: 33%;

    .image-container {
      margin-bottom: 30px;
    }
    .content-container {
      p {
        font-size: 34px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    flex: 1 0 25%;
    max-width: 25%;

    .content-container {
      p {
        font-size: 28px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .image-container {
      margin-bottom: 35px;
    }

    .content-container {
      p {
        font-size: 36px;
      }
    }
  }
}
</style>
