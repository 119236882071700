<template>
  <div class="blending-benefits-header">
    <div class="upper-container">
      <div class="container">
        <h4>{{ blendingBenefitsData.titleText }}</h4>
        <p>{{ blendingBenefitsData.textContent }}</p>
      </div>
    </div>
    <div class="water-container"></div>
    <div class="text-container">
      <div class="container">
        <h3>{{ blendingBenefitsData.secondaryTitle }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlendingBenefitsHeader',
  props: {
    blendingBenefitsData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.blending-benefits-header {
  .upper-container {
    .container {
      padding-top: 100px;

      h4 {
        @apply uppercase pb-16;
        font-size: 28px;
        letter-spacing: 3px;
      }

      p {
        @apply mx-auto w-full px-4;
        font-family: FuturaPtBook, serif;
        font-size: 24px;
        letter-spacing: 2px;
      }
    }
  }

  .water-container {
    @apply m-auto bg-bottom bg-no-repeat bg-contain;
    padding-top: 52%;

    background-image: url('~@/assets/blue-liquid.jpg');
  }

  .text-container {
    .container {
      h3 {
        @apply uppercase pb-16;
        font-size: 50px;
        letter-spacing: 3px;
        line-height: 1.1em;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .upper-container {
      .container {
        h4 {
          font-size: 38px;
        }

        p {
          @apply mx-auto w-4/5 px-0;
          font-size: 32px;
        }
      }
    }

    .text-container {
      .container {
        h3 {
          font-size: 85px;
          line-height: 1.1em;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .upper-container {
      .container {
        padding-top: 150px;

        p {
          @apply mx-auto w-3/5;
        }
      }
    }

    .text-container {
      .container {
        h3 {
          @apply pb-20;
          font-size: 70px;
          line-height: 1.5em;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .upper-container {
      .container {
        padding-top: 200px;

        p {
          @apply mx-auto w-2/5;
        }
      }
    }

    .text-container {
      .container {
        h3 {
          @apply pb-24;
          font-size: 90px;
        }
      }
    }
  }
}
</style>
