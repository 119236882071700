<template>
  <div class="application-list-container">
    <div class="container">
      <AnchorButtonPrimary
        class="button"
        button-text="GETTING STARTED"
        button-link="#preProjectChecklist"
      />

      <h3 v-html="applicationListData.titleText"></h3>
      <div class="applications">
        <ApplicationItem
          class="application-item"
          v-for="applicationItem in applicationListData.applications"
          :key="applicationItem.id"
          :application-item="applicationItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationItem from '@/components/ApplicationList/ApplicationItem'
import AnchorButtonPrimary from '@/components/ui/AnchorButtonPrimary'
export default {
  name: 'ApplicationListContainer',
  components: { AnchorButtonPrimary, ApplicationItem },
  props: {
    applicationListData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.application-list-container {
  @apply m-auto bg-bottom bg-no-repeat bg-contain;
  background-image: url('~@/assets/purple-liquid.jpg');
  padding-bottom: 150px;
  padding-top: 100px;

  .button {
    @apply inline-block px-6 py-3 mx-8;
    width: auto;
    font-size: 26px;
    margin-bottom: 100px;
  }

  h3 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 100px;
  }

  .applications {
    @apply flex flex-wrap justify-center;
    margin-top: 55px;

    .application-item {
      margin-bottom: 55px;
    }
  }

  @media screen and (min-width: 600px) {
    padding-top: 150px;
    padding-bottom: 300px;

    .button {
      @apply inline-block px-12 py-3;
      font-size: 34px;
      margin-bottom: 150px;
    }

    h3 {
      font-size: 38px;
      margin-bottom: 150px;
    }
  }

  @media screen and (min-width: 900px) {
    padding-bottom: 400px;

    h3 {
      font-size: 55px;
      line-height: 55px;
    }

    .applications {
      .application-item {
        margin-bottom: 100px;
      }
    }
  }
}
</style>
