<template>
  <div class="project-checklist-accordion-list">
    <h3 id="preProjectChecklist">Pre-project Checklist</h3>

    <Accordion
      class="accordion-item"
      v-for="accordionItem in accordionDataWithColors"
      :key="accordionItem.id"
    >
      <template v-slot:header>
        <div :class="`container accordion-header ${accordionItem.color}`">
          <img src="@/assets/white-checkmark.png" alt="Checkmark" />
          {{ accordionItem.titleText }}
        </div>
      </template>
      <template v-slot:content>
        <div class="container accordion-content">
          {{ accordionItem.textContent }}
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/ui/Accordion'

export default {
  name: 'ProjectChecklistAccordion',
  components: {
    Accordion
  },
  props: {
    accordionData: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      listColors: ['green', 'red', 'purple', 'gold', 'pink', 'light-green'],
      nextColorIndex: 0
    }
  },
  computed: {
    accordionDataWithColors() {
      return this.accordionData.map(accordionItem => {
        accordionItem.color = this.assignColor()

        return accordionItem
      })
    }
  },
  methods: {
    assignColor() {
      const thisColor = this.listColors[this.nextColorIndex]

      if (this.listColors.length - 1 === this.nextColorIndex) {
        this.nextColorIndex = 0
      } else {
        this.nextColorIndex += 1
      }

      return thisColor
    }
  }
}
</script>

<style lang="scss" scoped>
.project-checklist-accordion-list {
  @apply w-full;

  h3 {
    @apply uppercase pt-24 pb-16;
    font-size: 26px;
    letter-spacing: 3px;
  }

  .accordion-item {
    box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.2);

    .accordion-header {
      @apply w-full flex justify-center items-center uppercase py-12 text-left;
      color: #525e6e;
      font-size: 22px;
      letter-spacing: 4px;
      font-family: FuturaPtBook, serif;
      transition: all 0.3s linear;

      &:hover {
        color: rgba(82, 94, 110, 0.7);
      }

      img {
        @apply inline-block mx-4;
        width: 75px;
        height: 75px;
      }

      &.green {
        color: #2c4444;

        img {
          filter: brightness(0) saturate(100%) brightness(0) saturate(100%)
            invert(25%) sepia(23%) saturate(469%) hue-rotate(129deg)
            brightness(92%) contrast(98%);
        }
      }

      &.red {
        color: #b64a3a;

        img {
          filter: brightness(0) saturate(100%) invert(44%) sepia(10%)
            saturate(3178%) hue-rotate(320deg) brightness(83%) contrast(98%);
        }
      }

      &.purple {
        color: #5b3946;

        img {
          filter: brightness(0) saturate(100%) invert(23%) sepia(11%)
            saturate(1664%) hue-rotate(285deg) brightness(95%) contrast(87%);
        }
      }

      &.gold {
        color: #bc9353;

        img {
          filter: brightness(0) saturate(100%) invert(67%) sepia(19%)
            saturate(1038%) hue-rotate(358deg) brightness(87%) contrast(87%);
        }
      }

      &.pink {
        color: #906773;

        img {
          filter: brightness(0) saturate(100%) invert(49%) sepia(10%)
            saturate(1102%) hue-rotate(292deg) brightness(87%) contrast(88%);
        }
      }

      &.light-green {
        color: #647449;

        img {
          filter: brightness(0) saturate(100%) invert(44%) sepia(23%)
            saturate(617%) hue-rotate(41deg) brightness(91%) contrast(85%);
        }
      }
    }

    .accordion-content {
      @apply mx-auto pb-16 px-4;
      font-size: 20px;
      letter-spacing: 2px;
      max-width: 600px !important;
      font-family: 'FuturaPtDemi', sans-serif;
    }
  }

  @media screen and (min-width: 600px) {
    h3 {
      font-size: 36px;
    }

    .accordion-item {
      .accordion-header {
        @apply w-4/5 justify-start;
        font-size: 26px;

        img {
          @apply mx-16;
        }
      }

      .accordion-content {
        @apply px-8;
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .accordion-item {
      .accordion-header {
        @apply w-2/3;
        font-size: 32px;
      }

      .accordion-content {
        font-size: 28px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .accordion-item {
      .accordion-header {
        @apply w-1/2;
      }
    }
  }
}
</style>
